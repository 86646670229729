.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
  }
  
  .impressum-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0;
    font: inherit;
    text-decoration: underline;
    outline: none;  
  }
  
  .impressum-button:hover {
    text-decoration: none;  
  }
  