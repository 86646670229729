.footer {
    background-color: none;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1000; 
  }
  
  /* Stiländerung für den Impressum-Button, um ihn im Footer besser sichtbar zu machen. */
  .impressum-button {
      color: #ecf0f1;
    
  }
  