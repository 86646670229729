/* Header.css */

.header-container {
    width: 100%;               /* Make the header span the entire width of its parent container. */
    background-color: #333;    /* Set a dark background color for contrast. */
    color: #fff;               /* Use white text for contrast against the dark background. */
    padding: 10px 0 ;        /* Add some vertical and horizontal padding. */
    font-size: 1.5em;          /* Make the header text slightly larger than the default size. */
    text-align: center;        /* Center the header text. */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle shadow for depth. */
}

/* If you plan to add more components and styles, 
you might consider using more specific selectors or a class for the header instead of styling the div directly. 
For example: */

/* .header-container {
    ...
} */
